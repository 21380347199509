import * as React from "react"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import { Mousewheel, Autoplay, FreeMode } from "swiper"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/mousewheel"
import "swiper/css/free-mode"
export default function Styling({ data }) {
  return (
    <Layout>
        <Swiper
          modules={[Mousewheel, Autoplay, FreeMode]}
          spaceBetween={30}
          slidesPerView={2}
          mousewheel
          autoHeight
          autoplay={true}
          direction={"horizontal"}
          breakpoints={{
            // when window width is >= 320px
            0: {
              slidesPerView: "auto",
              spaceBetween: 10,
              autoHeight: true,
              direction: "vertical",
              mousewheel: false,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 40,
              direction: "horizontal",
            },
          }}
        >
          {data.allGraphCmsPicture.edges.map((photo, index) => {
            console.log(photo.node.image)
            return (
              <>
              <SwiperSlide>
                <GatsbyImage image={photo.node.image.gatsbyImageData} />
              </SwiperSlide>
          </>
            )
          })}
        </Swiper>
    </Layout>
  )
}

export const query = graphql`
  {
    allGraphCmsPicture {
      edges {
        node {
          image {
            gatsbyImageData
          }
          description
        }
      }
    }
  }
`
